




















































































































































import { Vue, Component } from "vue-property-decorator";

import { Input, Button, Table, TableColumn } from "element-ui";
import {
  MetaModule as metaStore,
  GPSServiceProviderModule as gpsServiceStore,
} from "@/store/modules";

import { AdminRouter } from "@/utils/routePathContsant";
import helper from "@/utils/helpers";
import { getApi } from "@/store/api/api";
import { Action } from "@/store/actionType";

@Component({
  components: {
    Input,
    Button,
    Table,
    TableColumn,
  },
})
export default class DutyOfficeClass extends Vue {
  // palikas: any[] = [];
  gpsServiceList: any[] = [];
  // districtName: any = [];
  filter: { page: number; name?: string } = {
    page: 1,
    // name: "",
    // district: "",
    // palika: "",
  };

  momentDate(date: any) {
    return helper.toMomentFormat(date);
  }

  get AdminRouter() {
    return AdminRouter;
  }

  // get routePath() {
  //   return AdminRouter;
  // }
  // get districts() {
  //   return metaStore.districtList;
  // }

  userType() {
    const user_profile = helper.getUserProfile();
    if (user_profile && user_profile.userType) {
      return user_profile.userType;
    }
  }

  async created() {
    let user = helper.getUserProfile();

    // metaStore.loadDis();
    await gpsServiceStore.loadAllGpsServiceProvider(this.filter);
    let a = gpsServiceStore.gpsServiceProviderList;

    if (user && user.userType === "vehicle_owner") {
      const filteredData = a.filter(
        (x) => x.profile.approvalStatus === "Approved"
      );
      this.gpsServiceList = filteredData;
    } else {
      this.gpsServiceList = a;
    }
  }

  // edit(id: any) {
  //   this.$router.push(AdminRouter.GPSServiceRegister + "?id=" + id);
  // }
  // async filterPalika() {
  //   this.filter.palika = "";
  //   this.palikas = await metaStore.loadMuncipality(
  //     parseInt(this.filter.district)
  //   );
  // }
  async filterGpsServiceProvider() {
    this.filter.page = 1;
    await gpsServiceStore.loadAllGpsServiceProvider(this.filter);
    this.gpsServiceList = gpsServiceStore.gpsServiceProviderList;
    // var filterData = gpsServiceStore.gpsServiceProviderList;

    // if (this.filter.name !== "") {
    //   this.filter.name = this.filter.name.toLowerCase();
    //   filterData = filterData.filter((x) =>
    //     x.profile.companyName.toLowerCase().includes(this.filter.name)
    //   );
    // }

    // this.gpsServiceList = filterData;
  }
  async clearFilter() {
    this.filter = {
      page: 1,
      // name: "",
      // district: "",
      // palika: "",
    };
    await gpsServiceStore.loadAllGpsServiceProvider(this.filter);
    this.gpsServiceList = gpsServiceStore.gpsServiceProviderList;
  }

  async onPageChange(val: number) {
    this.filter.page = val;
    await gpsServiceStore.loadAllGpsServiceProvider(this.filter);
    this.gpsServiceList = gpsServiceStore.gpsServiceProviderList;
  }
  // submit(e: any) {
  //   gpsServiceStore.createGpsServiceProvider();
  // }

  async downloadExcel(e: any) {
    const action = Action.gpscsv;
    const sheet_path = await getApi(action);
    const link = document.createElement("a");
    link.setAttribute("href", sheet_path.excelSheetPath);
    link.click();
  }
}
